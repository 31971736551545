import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const currentYear = new Date().getFullYear();

const data = {
  labels: [
    'PHP', 
    'Python',
    'Java',
    'JavaScript', 
    'jQuery', 
    'AJAX', 
    'React', 
    'HTML',
    'CSS',
    'XML',
    'SQL',
  ],
  
  datasets: [
    {
      label: 'Years of Experience',
      data: [
        (currentYear - 2007),
        (currentYear - 2019), 
        (2),  
        (currentYear - 2007), 
        (currentYear - 2008), 
        (currentYear - 2008), 
        (currentYear - 2020), 
        (currentYear - 2005),
        (currentYear - 2005),
        (currentYear - 2008),
        (currentYear - 2005),
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
}

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const LanguageChart = () => (
  <>
    <div className='header'>
      <h1 className='title'>Languages</h1>
    </div>
    <Bar data={data} options={options} height='200' />
  </>
);

export default LanguageChart;




