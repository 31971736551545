import './reset.css'
import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'

import Portfolio from './components/Portfolio'

ReactDOM.render(
  <Portfolio />, 
  document.getElementById('root')
);

