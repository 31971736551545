
import React from 'react'

import LanguageBar from '../charts/LanguageBar'
import DatabaseBar from '../charts/DatabaseBar'
import SearchBar from '../charts/SearchBar'
import AwsBar from '../charts/AwsBar'
import AppBar from '../charts/AppBar'
import HealthcareBar from '../charts/HealthcareBar'

// Stat Component Translation
const StatList = 
{
  'language':LanguageBar,
  'aws':AwsBar,
  'database':DatabaseBar,
  'search':SearchBar,
  'application':AppBar,
  'healthcare':HealthcareBar
};

// Refresh focus
function refreshFocus(objectName, objectList)
{
  this.setState({'name': objectName, 'objects': objectList});
}

function refreshStats(objectList)
{
  this.setState({'objects': objectList});
}

// return keys from an object
function getKeys(objectRef)
{
  if (!typeof objectRef == 'object')
  {
    return false;
  }

  return Object.keys(objectRef);
}

// Clear Objects
function clearObject(state)
{
  // No object name passed, clear the content pane
  if (state == 'none')
  {
    return true;
  }

  return false;
}

class Portfolio extends React.Component
{
  render ()
  {
    return (
      <div className='wrapper'>
      <div className='stat-head'>
          <a href='https://portfolio.dougseitz.com'>
            <ProfileViewer />
          </a>
          <span className='stat-head-menu'>
            <a href='https://www.linkedin.com/in/doug-seitz-1b04575/' target='_blank' rel='noreferrer'>
              <img className='stat-head-linkedin' src='assets/linkedin-icon-5.png' alt='Linkedin' />
            </a>
          </span>
          <span className='stat-head-menu'><a href='assets/Resume.pdf' target='_blank' rel='noreferrer'>Resume</a></span>
      </div>
      <div className='content-wrapper'>
        <div className='focus'>
          <ContentPane name='none' />
        </div>
        <div className='content'>
          <Stats objects={StatList} />
        </div>
      </div>
      </div>
    );
  }
}

class ProfileViewer extends React.Component
{
  constructor(props)
  {
    // Set object defaults
    super(props);
    this.state = {'img': 'assets/static.gif', 'stencil_class': 'stencil-light-off'};

  }

  componentDidMount() 
  {
    this.timer_id = setInterval(() => this.switchPic(), 2000);
  }

  switchPic()
  {
    this.setState({'img': 'assets/profile-pic-dadada.png', 'stencil_class': 'stencil-light-on'});
    clearInterval(this.timer_id);
  }

  render() 
  {
    return (
      <div>
        <div>
          <img className='stat-head-pic head-pic-border' src='assets/profile-pic-border.png' alt='Profile Pic' />
          <img className='stat-head-pic' src={this.state.img} alt='Profile Pic' />
        </div>
        <div className={this.state.stencil_class}>
          <img className='head-pic-border head-stencil' src='assets/name_stencil.png' alt='Doug Seitz' />
        </div>
      </div>
    );
  }
}

class Stats extends React.Component 
{
  constructor (props)
  {
    // Set object defaults
    super(props);
    this.state = {objects: this.props.objects};

    // bind the refresh fuction to this object
    refreshStats = refreshStats.bind(this)
  }

  // Build stat list
  build (props)
  {
    var stats = getKeys(props.objects).map((alias) =>  
      <Stat key={alias} name={alias} />
    ); 

    return stats;
  }

  refresh ()
  {
    refreshStats('none');
  }

  render () 
  {
    // Detect clearing of object output
    if (clearObject(this.state.objects) === true)
    {
      return('');
    }

    // Build stats list
    const StatsList = this.build({'objects': StatList});

    return (
      <div className='stat-wrapper'>
        {StatsList}
      </div>
    );
  }
}

class Stat extends React.Component 
{
  constructor (props) 
  {
    super(props);

    this.state = {name: this.props.name, class: 'stat'};
  }

  focusStat (statName)
  {
    refreshFocus(statName, StatList);
    refreshStats('none');
  }

  render () 
  {
    // Detect clearing of object output
    if (clearObject(this.state.name) === true)
    {
      return('');
    }

    // Determine whicih stat to render
    const StatName = StatList[this.state.name];
  
    return (
      <div className={this.state.class} onClick={(event) => this.focusStat(this.state.name)}><StatName /></div>
    );
  }
}

class ContentPane extends React.Component
{
  constructor (props) 
  {
    // Set object defaults
    super(props);
    this.state = {name: this.props.name, objects: []};

    // bind the refresh fuction to this object
    refreshFocus = refreshFocus.bind(this)
  }

  // Refresh the objects in the content pane
  refresh (stateName, stateObject)
  {
    refreshFocus(stateName, stateObject);

    if (stateName == 'none')
    {
      refreshStats();
    }
  }

  // Build the menu
  menuItem (props)
  { 
    var menu = getKeys(props.objects).map((alias) =>  
      <li key={alias.toLowerCase()}  onClick={(event) => this.refresh(alias.toLowerCase(), props.objects)}>
        {alias.toUpperCase()}
      </li>
    ); 

    menu.push(
      <li key='BACK'  onClick={(event) => this.refresh('none', props.objects)}>
        {'< BACK'}
      </li>
    );

    return menu;
  }

  render ()
  {
    // Detect and clear objects
    if (clearObject(this.state.name) === true)
    {
      return('');
    }

    // Determine which object to render
    const ObjectName = this.state.objects[this.state.name];

    //Build the object menu
    const menuHtml = this.menuItem({'objects': this.state.objects});
    
    return(
      <div className='focus-wrapper'>
        <div className='focus-left'></div>
        <div className='focus-middle'><ObjectName /></div>
        <div className='focus-right'>
          <ul className='focus-menu'>
            {menuHtml}
          </ul>
        </div>
      </div>
    );
  }
}

export default Portfolio;